import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundSection from "../components/BackgroundSection"
import MainContent from "../components/atoms/MainContent"
import Heading from "../components/atoms/Heading";
import Paragraph from "../components/atoms/Paragraph"
import {css} from "@emotion/core";

const ContainerStyles = css`
  padding: 80px;
  text-align: left;
  @media(max-width: 767px) {
    padding: 30px;
  }
`

const TabContainerStyles = css`
  margin-bottom: 20px;
`

const TabStyles = css`
  margin-right: 10px;
  padding: 5px;
  font-size: 24px;
  font-family: 'Noto Sans JP', sans-serif;
  color: #ffffff;
  background-color: #2bbbad;
  outline: none;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  transition: .2s ease-out;
  &:hover {
    background-color: #26a69a;
  }
  @media(max-width: 767px) {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 2px;
    font-size: 16px;
  }
`

class Tabs {
  static Backend = "バックエンド"
  static Frontend = "フロントエンド"
  static Infra = "インフラ"
  static Db = "DB"
  static Tool = "ツール"
}

const SkillPage = () => {
  const [tab, setTab] = useState(Tabs.Backend)
  return (
    <Layout>
      <BackgroundSection>
        <SEO title="Skill Page"/>
        <MainContent>
          <div css={ContainerStyles}>
            <Heading level={2}>スキル</Heading>

            <div css={TabContainerStyles}>
              <button css={TabStyles} onClick={() => {setTab(Tabs.Backend)}}>{Tabs.Backend}</button>
              <button css={TabStyles} onClick={() => {setTab(Tabs.Frontend)}}>{Tabs.Frontend}</button>
              <button css={TabStyles} onClick={() => {setTab(Tabs.Infra)}}>{Tabs.Infra}</button>
              <button css={TabStyles} onClick={() => {setTab(Tabs.Db)}}>{Tabs.Db}</button>
              <button css={TabStyles} onClick={() => {setTab(Tabs.Tool)}}>{Tabs.Tool}</button>
            </div>

            { tab === Tabs.Backend &&
            <div>
              <Heading level={3}>- Go (使用歴：1年)</Heading>
              <Paragraph>
                2019年からgqlgenで作ったGraphQLサーバーを個人開発に取り入れ、
                その後業務で使用。
                AWS API Gateway, Lambda, DynamoDBを使用したサーバレス・
                マイクロサービスアーキテクチャでの開発や、GraphQLのBFF・gRPC
                で通信する3つのマイクロサービス構成のサービス運用で使用。
              </Paragraph>
              <br/>

              <Heading level={3}>- Java (使用歴：5年)</Heading>
              <Paragraph>
                ソーシャルゲーム・マッチングアプリ・Webサービスの開発で使用。
                Java8を初めて扱った業務では、ラムダ式を苦労してキャッチアップ・
                活用して新規開発を行なった。
                リリース前、運用中の負荷試験をJMeterで担当。
                シナリオ作成からJavaコードのパフォーマンス修正まで実施。
              </Paragraph>
              <br/>

              <Heading level={3}>- Python (使用歴：2年)</Heading>
              <Paragraph>
                個人開発でScrapyでスクレイピング、Flaskでapiを作成し、チャート画像を生成して出力するサービスや、
                仮想通貨の自動売買ボットスクリプトを開発。
                業務では本番DBのデータ移行スクリプト作成時に使用。
              </Paragraph>
              <br/>

              <Heading level={3}>- Kotlin (使用歴：半年)</Heading>
              <Paragraph>
                サーバーサイドKotlinを新規開発で使用。
                Javaの経験を生かして短期間でキャッチアップした。
              </Paragraph>
            </div>
            }

            { tab === Tabs.Frontend &&
            <div>
              <Heading level={3}>- JavaScript (使用歴：3年)</Heading>
              <Paragraph>
                フロントエンドの開発時に使用。共にReact.jsやVue.jsを使用することが多い。
                jQueryは案件で4ヶ月程使用した。
              </Paragraph>
              <br/>

              <Heading level={3}>- TypeScript (使用歴：1年)</Heading>
              <Paragraph>
                個人開発で使用し独習後、新規開発案件でNuxt.jsと共に導入から行なった。
                当サイトもTypeScriptで作成、2019年以降はJavaScriptより優先して使用している。
              </Paragraph>
              <br/>

              <Heading level={3}>- Vue.js (使用歴：1年)</Heading>
              <Paragraph>
                Nuxt.jsを用いて1人で新規開発案件を行なったこともあり、実装は不自由なく行えるレベル。
                その際Atomic Designを本で独学し、ほぼ全てのコンポーネントをatomsから組み上げた。
              </Paragraph>
              <br/>

              <Heading level={3}>- React.js (使用歴：1年)</Heading>
              <Paragraph>
                Vue.jsと並行して業務で使用中。
                独学では仮想通貨の一覧を表示するプログラムを<a href="https://mabui.org/react-material-ui-demo/" target="_blank" rel="noopener noreferrer">ブログで公開</a>、
                日本株のチャート一覧を生成して検索できるサービスの個人開発で勉強。
              </Paragraph>
            </div>
            }

            { tab === Tabs.Infra &&
            <div>
              <Heading level={3}>- AWS (使用歴：5年~)</Heading>
              <Paragraph>
                エンジニアキャリアの初めからGCPと共に使用。
                2019年にAWSソリューションアーキテクト アソシエイト資格取得。
                業務で使用したサービスは下記。
              </Paragraph>
              <Paragraph>
                Lambda | API Gateway | SES | CloudFormation | CLI | CodeCommit | CodeBuild | CodeDeploy | CodePipeline | RedShift | S3 | Cloud Front | ELB | EC2 | Route53 | IAM | RDS | Aurora | Cloud Watch | CloudTrail | VPC | Elasticsearch Service | ElastiCache(Redis|Memcached) | WAF
              </Paragraph>
              <br/>

              <Heading level={3}>- GCP (使用歴：5年~)</Heading>
              <Paragraph>
                業務で使用したサービスは下記。
              </Paragraph>
              <Paragraph>
                GKE | Cloud SQL | GCS | BigQuery | GAE | GCE
              </Paragraph>
            </div>
            }

            { tab === Tabs.Db &&
            <div>
              <Heading level={3}>- MySQL (使用歴：7年~)</Heading>
              <Paragraph>
                MySQL5.6~8を使用。dockerコンテナやAWSマネージドのサービスも使用。
                クライアントはSequel Proを使用している。
              </Paragraph>
              <br/>

              <Heading level={3}>- Cloud Datastore (使用歴：2年)</Heading>
              <Paragraph>
                GAEでのソーシャルゲーム開発で使用。
              </Paragraph>
            </div>
            }

            { tab === Tabs.Tool &&
            <div>
              <Heading level={3}>- GraphQL (使用歴：1年半)</Heading>
              <Paragraph>
                使用しつつブログにまとめたり、個人開発でサーバー・クライアントを導入して勉強。
                案件ではApollo Clientでのフロントエンド業務、gqlgenでのバックエンド業務を担当。
              </Paragraph>
              <br/>

              <Heading level={3}>- gRPC (使用歴：半年)</Heading>
              <Paragraph>
                GraphQLのBFF(フロント向けサーバー)、それに紐づくgRPCで通信するマイクロサービスが3つある環境での開発を担当。
                gRPCの通信サイズ上限の問題解消も行なった。
              </Paragraph>
              <br/>

              <Heading level={3}>- Kubernetes (使用歴：半年)</Heading>
              <Paragraph>
                KubernetesのManifestで構築されたインフラの改修作業を担当。
                github上でterraformを実行するatlantisサーバーの不具合調査・解消や、管理画面のステージング環境用意、フロントエンドのgkeコンテナ移行(firebase→gke)を行った。
              </Paragraph>
              <br/>

              <Heading level={3}>以下過去に使用したツールを列挙しています。</Heading>
              <Heading level={4}>- フレームワーク</Heading>
              <Paragraph>
                gqlgen | Gatsby.js | Next.js | React.js | Redux | Vue.js | Vuex | Nuxt.js | Flask | Django | Scrapy | Laravel | Spring | Spring boot | Seaser2 | Slim3
              </Paragraph>
              <br/>

              <Heading level={4}>- ORM</Heading>
              <Paragraph>
                GORM | SQLBoiler | SQLAlchemy | dataset | doma | JPA | Hibernate | MyBatis | DBFlute
              </Paragraph>
              <br/>

              <Heading level={4}>- Other</Heading>
              <Paragraph>
                Docker | Bazel | Argo CD | Detadog | Terraform | CirclCI | Redis | Memcached | fluentd | jenkins | rundeck | Jmeter | gradle | flyway | elasticsearch | Thymeleaf | ansible | maven | sonatype nexus | icinga | kibana | grafana | NewRelic | boto3 | JUnit | Sendgrid
              </Paragraph>
              <Paragraph>
                firebase | Puppeteer | SCSS | Stylus | Pug | GraphQL Faker | ApolloClient | Vue Apollo | Vuetify | Algolia | Stripe | Fastly | Bootstrap | jQuery
              </Paragraph>
              <Paragraph>
                figma | Adobe XD | asana | backlog | Github | Jira | Bitbucket | ngrok | cacoo | git | gitlab | confluence | trello | Zapire | chatwork | Slack | Google spreadsheet
              </Paragraph>
            </div>
            }

          </div>
        </MainContent>
      </BackgroundSection>
    </Layout>
  )
}

export default SkillPage
